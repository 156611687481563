import React from 'react'
import { graphql } from 'gatsby'
import Startpage from './startage'
import linkResolver from '../../contentful/linkResolver'
import localizationFilter from '../../contentful/localizationFilter'
import { renderRichText } from '../../contentful/renderRichText'
import moment from 'moment'
import 'moment/locale/sv'

const StartPageContainer = ({ data, pageContext }) => {
  const { contentfulStartpage, contentfulNews, contentfulBlogPosts } = data
  const { locale } = pageContext

  moment.locale(contentfulStartpage.node_locale.slice(0, 2))

  const startpage = {
    _doc: contentfulStartpage,
    locale: contentfulStartpage.node_locale,
    metaTitle: contentfulStartpage.metaTitle || contentfulStartpage.heading,
    metaDescription: contentfulStartpage.metaDescription,
    productionTitle: contentfulStartpage.productionTitle,
    productionText: contentfulStartpage.productionText
      ? renderRichText(contentfulStartpage.productionText.json, locale)
      : null
  }

  const hero = {
    heading: contentfulStartpage.hero.heading,
    linkText: contentfulStartpage.hero.linkText,
    link: linkResolver(contentfulStartpage.hero.link),
    text: contentfulStartpage.hero.text?.childMarkdownRemark.html,
    image: {
      fluid: contentfulStartpage.hero.image?.fluid,
      alt: ''
    }
  }

  const news = contentfulNews.edges
    .filter(localizationFilter)
    .map(({ node }) => {
      return {
        heading: node.heading,
        listText: node.listText ? node.listText.listText : '',
        link: linkResolver(node),
        type: node.pageType, //localize
        published: moment(node.published).format('MMM D')
      }
    })

  const articles = contentfulBlogPosts.edges
    .filter(localizationFilter)
    .map(({ node }) => {
      return {
        heading: node.heading,
        text: node.listText ? node.listText.listText : '',
        link: linkResolver(node),
        image: node.listImage ? node.listImage.fluid : null,
        category: node.category ? node.category.name : null,
        published: node.published
      }
    })

  let teasers = []
  if (contentfulStartpage.teasers) {
    teasers = contentfulStartpage.teasers
      .filter(localizationFilter)
      .map(node => {
        return {
          title: node.title,
          linkText: node.linkText,
          text: node.text ? node.text.text : null,
          link: linkResolver(node.link)
        }
      })
  }

  return (
    <Startpage
      doc={startpage}
      hero={hero}
      news={news}
      articles={articles}
      teasers={teasers}
    />
  )
}

export default StartPageContainer

export const pageQuery = graphql`
  query($locale: String!) {
    contentfulStartpage(node_locale: { eq: $locale }) {
      node_locale
      metaDescription
      metaTitle
      hero {
        heading
        linkText
        link {
          ...ContentfulLink
        }
        image {
          fluid(maxWidth: 1920, maxHeight: 1080, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      productionTitle
      productionText {
        json
      }
      teasers {
        ...Teasers
      }
    }

    contentfulNews: allContentfulPage(
      filter: {
        pageType: { in: ["News article", "Press release"] }
        node_locale: { eq: $locale }
      }
      limit: 4
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          node_locale
          enableLocalization
          slug
          heading
          published
          listText {
            listText
          }
          pageType
        }
      }
    }

    contentfulBlogPosts: allContentfulPage(
      filter: { pageType: { eq: "Blog post" }, node_locale: { eq: $locale } }
      limit: 4
      sort: { fields: [published], order: DESC }
    ) {
      edges {
        node {
          node_locale
          enableLocalization
          heading
          pageType
          published
          slug
          category {
            name
          }
          listText {
            listText
          }
          listImage {
            fluid(maxWidth: 400, maxHeight: 393, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
