import React from 'react'
import { Link } from 'gatsby'
import { LayoutContext } from '../../layout'
import { TiArrowRight } from 'react-icons/ti'
import './newsList.scss'

function NewsList({ title, items, link }) {
  const { localization } = React.useContext(LayoutContext)

  if(items.length === 0) return null

  const listItems = items.map((item, index) => {
    return (
      <li key={index}>
        <Link to={item.link}>
          <h3 className="heading-3">
            <span>{item.heading}</span>
          </h3>
          <p className="newslist__date meta">
            {item.published}
          </p>
          <p>
            <span className="tag">
              {item.type === 'News article'
                ? localization.news_tag
                : localization.press_tag}
            </span>
            {item.listText}
          </p>
        </Link>
      </li>
    )
  })

  return (
    <div className="news-list">
      {title && (
        <h2 className="news-list__heading heading-2 heading-2--blue">
          {title}
        </h2>
      )}
      <ul className="news-list__items">{listItems}</ul>
      <div className="flex-right">
        <Link
          className="news-list__all arrow-link"
          to={link}
          aria-label={localization.show_all_news}
        >
          {localization.show_all}
          <TiArrowRight />
        </Link>
      </div>
    </div>
  )
}

export default NewsList
