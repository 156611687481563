import React from 'react'
// import { Link } from 'gatsby'
// import ContentWrapper from '../contentWrapper'
// import ListText from '../listText'
import { ParallaxProvider, Parallax } from 'react-scroll-parallax'
// import { FiArrowRight } from 'react-icons/fi'
import './hero.scss'
import '../buttons/button.scss'
import Img from 'gatsby-image'

function Hero({ text, title, link, linkText, image }) {
  return (
    <ParallaxProvider>
      <div className="hero">
        <div className="hero__canvas">
          {/* <ContentWrapper modifier="wide"> */}
          <div className="hero__inner">
            <h1 className="hero__heading heading-1">{title}</h1>
            {/* <ListText modifier="larger">{text}</ListText>
              <Link
                className="button button--icon button--invert"
                aria-label={title}
                to={link}
              >
                {linkText}
                <FiArrowRight />
              </Link> */}
          </div>
          {/* </ContentWrapper> */}
        </div>
        <div className="hero__overlay"></div>
        <Parallax className="hero__image" y={[0, 0]} tagOuter="div">
          {image && (
            <Img
              fluid={image}
              placeholderClassName="hero__image lazy"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%'
              }}
            />
          )}
        </Parallax>
        <div className="hero__bottom">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: '100%', width: '100%' }}
          >
            <path
              d="M-2.25,82.39 C248.30,108.05 373.02,35.03 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: 'none', fill: 'hsla(218, 14%, 95%, 1)' }}
            ></path>
          </svg>
        </div>
      </div>
    </ParallaxProvider>
  )
}
export default Hero
