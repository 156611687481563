import React, { useEffect,useContext, useState, useRef } from 'react'
import { LayoutContext } from '../../layout'
import SvgGauge from 'svg-gauge'
import './gauge.scss'

const defaultOptions = {
  animDuration: 1,
  showValue: true,
  dialStartAngle: -87,
  dialEndAngle: -90.001,
  max: 1450,
  gaugeClass: 'the-gauge'
}

function Gauge({ title, text }) {
  const gaugeEl = useRef(null)
  const gaugeRef = useRef(null)

  const { localization } = useContext(LayoutContext)
  const [reactorValue, setReactorValue] = useState(null)
  const [reactorComparisons, setReactorComparisons] = useState({
    lamps: 0,
    phones: 0,
    tvs: 0,
    coffee: 0
  })
  const [reactorError, setReactorError] = useState(null)

  useEffect(() => {
    function fetchData(retry = true) {
      fetch('/.netlify/functions/getReactorOutput')
        .then(res => res.json())
        .then(json => {
          setReactorValue(json)
          setReactorComparisons({
            lamps: Math.round(json.output / 9),
            phones: Math.round(json.output / 10),
            tvs: Math.round(json.output / 80),
            coffee: Math.round(json.output / 120)
          })
          setTimeout(() => {
            fetchData()
          }, 62000)
        })
        .catch(error => {
          console.error(error)
          if (retry) {
            setTimeout(() => {
              fetchData(false)
            }, 500)
          } else {
            setReactorError(
              localization.production_error
            )
          }
        })
    }

    fetchData()
  }, [localization])

  useEffect(() => {
    if (!gaugeRef.current) {
      gaugeRef.current = SvgGauge(gaugeEl.current, defaultOptions)
      gaugeRef.current.setValue(0)
    }
  }, [reactorValue])

  useEffect(() => {
    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            if (reactorValue) {            
              gaugeRef.current.setValueAnimated(reactorValue.output, 1)
            }
          } else {
            gaugeRef.current.setValue(0, 1)
          }
        })
      },
      {
        rootMargin: '-100px'
      }
    )
    observer.observe(gaugeEl.current)
  })

  return (
    <React.Fragment>
      <h2 className="heading-2 heading-2--blue">{title}</h2>

      <div className="list-text list-text--larger">
        {text}
      </div>
      <div className="gauge__grid">
        { false && // hide grid groups
        <div
          className={
            reactorValue && reactorValue.output > 0
              ? 'gauge__grid__group'
              : 'gauge__grid__group gauge__grid__group--offline'
          }
        >
          <div>
            <strong className="heading-2 heading-2--blue nowrap margin-b-sm">
              {reactorComparisons.lamps} <span className="sub">{localization.millions}</span>
            </strong>
            <span className="heading-5">{localization.production_lamps}</span>
          </div>
          <div>
            <strong className="heading-2 heading-2--blue nowrap margin-b-sm">
              {reactorComparisons.coffee} <span className="sub">{localization.millions}</span>
            </strong>
            <span className="heading-5">{localization.production_coffee}</span>
          </div>
        </div>
        }
        <div
          className={
            reactorValue
              ? 'gauge__inner'
              : 'gauge__inner gauge__inner--offline'
          }
        >
          <div className="gauge__value">
            <span className="tag">MW</span>
          </div>
          <div ref={gaugeEl} className="gauge-container" />
        </div>

        { false && // hide grid groups
        <div
          className={
            reactorValue && reactorValue.output > 0
              ? 'gauge__grid__group'
              : 'gauge__grid__group gauge__grid__group--offline'
          }
        >
          <div>
            <strong className="heading-2 heading-2--blue nowrap margin-b-sm">
              {reactorComparisons.phones} <span className="sub">{localization.millions}</span>
            </strong>
            <span className="heading-5">{localization.production_phones}</span>
          </div>
          <div>
            <strong className="heading-2 heading-2--blue nowrap margin-b-sm">
              {reactorComparisons.tvs} <span className="sub">{localization.millions}</span>
            </strong>
            <span className="heading-5">{localization.production_tv}</span>
          </div>
        </div>
        }
      </div>
      {reactorError && (
        <p className="error-message">{localization.error} {reactorError}</p>
      )}
    </React.Fragment>
  )
}
export default Gauge
