import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './articleTeaser.scss'

function ArticleTeaser({ title, link, image, text, published, category }) {
  return (
    <article className="article-teaser">
      <Link to={link}>
        {image &&
          <Img fluid={image} placeholderClassName="lazy" />
        }        
        <div className="article-teaser__body">
          <h3 className="heading-3">{title}</h3>
          <time className="meta">{published}</time>
          <div className="list-text">
          <p>
            {category && 
              <span className="tag">{category}</span>
            }
            {text}
            </p>
          </div>
        </div>
      </Link>
    </article>
  )
}
export default ArticleTeaser
