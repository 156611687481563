import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import './articleTeaserLarge.scss'
import { TiArrowRight } from 'react-icons/ti'

var moment = require('moment')
require('moment/locale/sv')

function ArticleTeaserLarge({
  title,
  link,
  image,
  text,
  published,
  category,
  secondaryLink,
  secondaryLinkText
}) {
  return (
    <article className="article-teaser-large">
      {image &&
        <Img fluid={image} />
      }
      <div className="article-teaser-large__body">
        <h3 className="heading-1">{title}</h3>
        <time className="meta">{moment(published).format('LL')}</time>
        <div className="list-text list-text--medium">
          <p>
            {category &&
              <span className="tag">{category}</span>
            }            
            {text}
          </p>
        </div>
        <div className="article-teaser-large__body__actions">
          <Link
            className="button"
            aria-label={title}
            to={link}
          >
            Läs artikel
          </Link>
          <Link to={secondaryLink} className="arrow-link">
            {secondaryLinkText}
            <TiArrowRight />
          </Link>
        </div>
      </div>
    </article>
  )
}
export default ArticleTeaserLarge
