import ArticleTeaser from '../articleTeaser'
import React from 'react'
import './articleTeasers.scss'

function ArticleTeasers({ articles, addPlaceholder = false, useSlider = false }) {

  let teasers = articles.map((article, index) => {
    return (
      <ArticleTeaser
        key={index}
        title={article.heading}
        image={article.image}
        text={article.text}
        link={article.link}
        published={article.published}
        category={article.category}
      />
    )
  })

  const listMarkup = (
    <div className={`article-teasers ${useSlider ? 'article-teasers--slider': ''}`}>
      <div className="article-teasers__items">
        {teasers}
        {addPlaceholder && <div></div>}
      </div>
    </div>
  )

  return listMarkup
}
export default ArticleTeasers
